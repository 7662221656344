<template>
    <div id="commande-list">

        <b-modal ref="my-modal" scrollable title="Détail bon de retour" ok-only ok-title="Annuler" size="xl"
            cancel-variant="outline-secondary">

            <b-table :bordered="true" :small="true" :hover="true" :no-border-collapse="true" :items="commandesSelected"
                :fields="fields" />

        </b-modal>
        <b-overlay :show="show" spinner-variant="primary">
            <b-card>

                <ag-grid :withFilters="true" :filterComponent="AgGridDateFilter" :agGrid="agGrid"
                    pageTitle="Liste des retours" @refresh="loadData" @filterClicked="filterClicked" />

            </b-card>
        </b-overlay>

    </div>
</template>

<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import AgGridDateFilter from "./AgGridDateFilterCurrMonth.vue"

export default {
    components: {
        // VueAutosuggest,
        AgGrid
    },
    data() {
        return {
            AgGridDateFilter: AgGridDateFilter,
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            agGrid: {
                columnDefs: [{
                    headerName: "N°",
                    field: "No",
                },
                {
                    headerName: "Date Retour",
                    field: "Posting_Date",
                    valueGetter: function (tr) {
                        return tr.data.Posting_Date.substring(0, 10);
                    },
                    //cellClass: 'right-customeCellRender',
                    //headerClass: 'right-customeHeaderRender',
                },
                {
                    sortable: false,
                    resizable: false,
                    filter: false,
                    headerName: '',
                    cellClass: 'centered-customeCellRender',
                    cellRendererFramework: 'buttonCellRenderer',
                    cellRendererParams: { onButtonClick: this.showDetails, icon: "read_more", tooltip: "voir les détails" },
                    width: 80
                },
                {
                    sortable: false,
                    resizable: false,
                    filter: false,
                    headerName: '',
                    cellClass: 'centered-customeCellRender',
                    cellRendererFramework: 'buttonCellRenderer',
                    cellRendererParams: { onButtonClick: this.printDoc, icon: "picture_as_pdf", tooltip: "Imprimer" },
                    width: 80
                },
                ],
                rows: []
            },
            fields: [{
                label: 'Code Article',
                key: 'No',
                sortable: true
            },
            {
                label: 'Désignation article',
                key: 'Description',
                sortable: true
            },
            {
                label: 'Prix',
                key: 'Unit_Price',
                sortable: true
            }, {
                label: 'Quantité',
                key: 'Quantity',
                sortable: true
            }
            ],
            commandesSelected: [],
            show: true,
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.loadData();
    },
    methods: {
        async printDoc(event) {
            this.show = true;
            await this.$http.put("return_header", { Print: true }, { params: { No: "'" + event.No + "'" } }).catch(() => this.show = false);
            let response = await this.$http.get("file/document/" + event.No.replace(/:/g, "") + ".pdf?type=return", { responseType: "blob" }).catch(() => this.show = false);
            this.show = false;
            const fileURL = URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.href = fileURL;
            a.target = "_blank";
            a.rel = "noopener noreferrer";
            a.click();
            URL.revokeObjectURL(fileURL);
        },
        filterClicked(obj) {
            this.startDate = obj.sDate;
            this.endDate = obj.eDate;
            this.loadData();
        },
        async showDetails(event) {
            this.show = !this.show;
            let response = await this.$http.get("return_line/findWhere", {
                params: {
                    Document_No: "'" + event.No + "'"
                }
            }).catch(() => this.show = false);
            this.commandesSelected = response.data;
            this.show = !this.show;
            this.$refs['my-modal'].show();
        },
        async loadData() {
            this.show = true;
            let response = await this.$http.post("return_header/findWhere", this.generateDateFilters());
            this.agGrid.rows = response.data;
            this.show = false;
        },
        generateDateFilters() {
            const filters = [
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "gt"
                },
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "lt"
                }
            ];
            // Convert start date string to Date and adjust by -1 day
            const startDateObj = new Date(this.startDate);
            startDateObj.setDate(startDateObj.getDate() - 1);
            // Convert end date string to Date and adjust by +1 day
            const endDateObj = new Date(this.endDate);
            endDateObj.setDate(endDateObj.getDate() + 1);
            // Format adjusted dates as strings in yyyy-MM-dd format
            const formattedStartDate = startDateObj.toISOString().split('T')[0];
            const formattedEndDate = endDateObj.toISOString().split('T')[0];
            // Set adjusted date values in the filter objects
            filters[0].value = formattedStartDate;
            filters[1].value = formattedEndDate;
            return filters;
        },
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>